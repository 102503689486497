import React from 'react'
import {
  Breadcrumbs,
  Hero,
  NotFound,
  Page,
} from '@components'

const meta = {
  title: 'Stranica nije pronađena | Protupožarna zaštita',
  description: '',
}

const Homepage = () => (
  <Page
    isHomepage
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/404/', name: 'Stranica nije pronađena'},
      ]}
    />
    <Hero
      title="Pogreška 404"
      subtitle="Stranica nije pronađena"
      description=""
    />
    <NotFound />
  </Page>
)

export default Homepage